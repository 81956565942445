<template>
	<div class="trips-card">
		<div class="col-8 col-md-4 px-0 h-100">
			<div class="bg-white rounded-0 h-100 overflow-auto mb-0">
				<div class="">
					<h5 class="header-title p-4">Active Trips {{totalTrips ? `(${totalTrips})` : ''}}</h5>
				</div>

				<div class="px-3">
					<div class="" v-if="activeTrips.length">
						<div
							class="list-group list-group-flush"
							v-if="searchResults.length"
							>
							<div
								class="
                      list-group-item list-group-item-action
                      flex-column
                      pointer
                    "
								v-for="trip in searchResults"
								:key="trip.id"
								href="#"
								:ref="`activeTrip_${trip.id}`"
								:id="`activeTrip_${trip.id}`"
								@click.prevent="onTripSelected(trip)"
								:class="selectedTrip !== null && selectedTrip.id === trip.id? 'list-group-item--active': ''"
								>
								<div class="d-flex w-100 justify-content-between">
									<h3 class="mb-2">{{ trip.route.route_code }}</h3>
								</div>

								<small class="mb-1">
									{{ trip.route.pickup }} - {{ trip.route.destination }}
								</small>

								<div class="row mt-3">
									<div class="col">
										<p>
											<small class="label-text">Start Time:</small>
											<span class="">{{ trip.start_trip | dateformat }}</span>
										</p>
									</div>

									<div class="col">
										<p>
											<small class="label-text">Vehicle:</small>
											<span class="">{{ trip.driver.vehicle?.brand }} {{ trip.driver.vehicle?.name }}</span>
										</p>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<p>
											<small class="label-text">Driver:</small>
											<span class="">
												{{ trip.driver.fname }} {{ trip.driver.lname }}
											</span>
										</p>
									</div>

									<div class="col">
										<p>
											<small class="label-text">Vehicle type:</small>
											<span class=""> <span class="text-capitalize">{{ trip.driver.vehicle?.type }}</span> ({{trip.driver.vehicle?.seats}} seats) </span>
										</p>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<p>
											<small class="label-text">Plate #:</small>
											<span class="">{{ trip.driver.vehicle?.registration_number || 'N/A' }}</span>
										</p>
									</div>

									<div class="col">
										<p>
											<small class="label-text"># of Passengers:</small>
											<span class="">{{ trip.passengers_count || 'N/A' }}</span>
										</p>
									</div>
								</div>

							</div>
							<div v-if="loadingTrips" class="d-flex mt-5 mb-3 justify-content-center align-items-center w-100">
								<div class="spinner-border" role="status"></div>
							</div>
							<div
								v-else-if="canLoadMoreTrips"
								class="d-flex mt-5 mb-3 justify-content-center align-items-center w-100">
								<label class="pointer font-weight-bold" @click="loadMoreTrips">Show more trips</label>
							</div>
						</div>
						<div class="d-flex justify-content-center mt-8 px-4" v-else>
							<p class="text-center">
								Sorry, no active trips were found matching your search for
								<em>{{ searchQuery }}</em>
							</p>
						</div>
					</div>
					<div class="d-flex justify-content-center mt-8 px-4" v-else>
						<p class="text-center">There are currently no active trips</p>
					</div>
				</div>
			</div>
		</div>
		<div class="col-4 col-md-8 text-white cursor-pointer px-0 h-100 d-flex align-items-center justify-content-center" title="Click to view map" @click="close()">
			<p>Click to interact with map</p>
		</div>
	</div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ActiveTripsMapWidget',
  emits: ['trip-selected', 'close', 'initialized'],
  filters: {
    dateformat (v) {
      return moment(v).format('HH:mm')
    }
  },
  created () {
    const panelOptions = {
      highlightListener: (tripId) => {
        this.highlightTrip(tripId)
      }
    }
    this.$emit('initialized', panelOptions)
  },
  props: {
    searchQuery: { type: String },
    activeTrips: { type: Array },
    totalTrips: { type: Number, default: 0 },
    loadingTrips: { type: Boolean, default: false }
  },
  data () {
    return {
      selectedTrip: null,
      currentPage: 1
    }
  },
  computed: {
    searchResults () {
      if (this.searchQuery) {
        const searchResults = this.activeTrips.filter((trip) => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every((v) => {
              return (
                trip.route.pickup.toLowerCase().includes(v) ||
                    trip.route.destination.toLowerCase().includes(v) ||
                    trip.route.route_code.toLowerCase().includes(v) ||
                    trip.driver.fname.toLowerCase().includes(v) ||
                    trip.driver.lname.toLowerCase().includes(v)
              )
            })
        })
        return searchResults
      } else {
        return this.activeTrips
      }
    },
    canLoadMoreTrips () {
      return this.totalTrips > 0 ? this.activeTrips.length < this.totalTrips : false
    }
  },
  methods: {
    highlightTrip (tripId) {
      const trip = this.activeTrips.find((o) => o.id === tripId)
      if (trip) {
        this.selectedTrip = trip
        const refKey = `activeTrip_${tripId}`
        setTimeout(() => {
          this.$refs[refKey][0].scrollIntoView({ behavior: 'smooth', block: 'center' })
        }, 300)
      }
    },
    onTripSelected (trip) {
      this.selectedTrip = trip
      this.$emit('trip-selected', trip)
    },
    close () {
      this.$emit('close')
    },
    loadMoreTrips () {
      this.currentPage++
      this.$emit('load-more-trips', this.currentPage)
    }
  }
}
</script>

<style lang="scss" scoped>
$neutral-200: #ddd;
$neutral-400: #ACAFAE;
$neutral-800: #313533;
$neutral-600: #737876;

.list-group-item {
  color: $neutral-800;
  font-size: 0.75rem;
  border: none;
  border-radius: 1rem;
  box-shadow: 0px 10px 24px rgb(33 35 37 / 8%);
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
  }
  &:hover, &--active {
    background: $neutral-600;
    color: #FFFFFF;
  }
  .label-text {
    margin-right: .25rem;
    color: $neutral-400;
  }
}

.pointer {
  cursor: pointer;
}
</style>
