<template>
	<div
		class="modal fade"
		id="tripDetailsModal"
		tabindex="-1"
		role="dialog"
		aria-labelledby="tripDetailsLabel"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			v-if="selectedMarkerTripData"
			>
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title" id="tripDetailsLabel">Trip Details</h3>
					<button
						type="button"
						class="close"
						@click="close"
						aria-label="Close"
						data-dismiss="modal"
						>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="d-flex mt-2">
						<div class="font-bold">
							<p class="font-medium text-sm">
								Route Code: &nbsp;
								<span class="font-normal text-base">{{
									selectedMarkerTripData.route.route_code
								}}</span>
							</p>
							<p class="font-medium text-sm">
								Route: &nbsp;
								<span class="text-gray-type-1 font-normal text-base">{{
									selectedMarkerTripData.route.pickup
								}}</span>
							</p>
							<p class="font-medium text-sm">
								Destination: &nbsp;
								<span class="font-normal text-base">{{
									selectedMarkerTripData.route.destination
								}}</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<button
			type="button"
			class="hidden"
			data-toggle="modal"
			data-target="#tripDetailsModal"
			ref="modalToggle"
			></button>
	</div>
</template>

<script>
import ModalMixin from '@/mixins/modal'

export default {
  props: {
    show: Boolean,
    selectedMarkerTripData: Object
  },
  mixins: [ModalMixin],
  data () {
    return {}
  },
  computed: {},
  methods: {
    close (e) {
      const target = e && e.target
      target.click()
      this.$emit('close')
    }
  }
}
</script>
